/* You can add global styles to this file, and also import other style files */
@import "~nouislider/distribute/nouislider.min.css";

body {
    padding-top: 51px;
    /*
     * Disable browser pull-to-refresh, as that breaks offline support.
     * (As of writing, only supported by Chrome 62+)
     */
    overscroll-behavior: none;
}

/*
 * Add padding to top or bottom. Works well with col-* style classes.
 */
div.pad-top {
    padding-top: 8px;
}
div.pad-bottom {
    padding-bottom: 8px;
}

/*
 * Buttons
 */
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
    box-shadow: 1px 1px 5px 0px grey
}
.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
    box-shadow: 2px 2px 6px 0px grey
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
    box-shadow: 3px 3px 8px 0px grey
}

.btn-float-bottom-right {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 10;
}

/*
 * Text
 */
.text-large {
    font-size: large;
}
.text-x-large {
    font-size: x-large;
}
.text-larger {
    font-size: larger;
}

// Mute the link icon more
.text-muted {
    color: #ccc;
}

// Text inside of an <hr class="text" data-content="hello">
hr.text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    &:before {
        content: '';
        // use the linear-gradient for the fading effect
        // use a solid background color for a solid bar
        background: linear-gradient(to right, transparent, #818078, transparent);
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }
    &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;

        padding: 0 .5em;
        line-height: 1.5em;
        // this is really the only tricky part, you need to specify the background color of the container element...
        color: #818078;
        background-color: #fcfcfa;
    }
}

/*
 * Tables
 */
td.valign-bottom {
    vertical-align: bottom !important;
}
td.valign-middle {
    vertical-align: middle !important;
}

/*
 * Modify formatting in a documentation/aside block.
 */
aside {
    ul, ol {
        padding-left: 16px;
    }
}
.help-backdrop {
    opacity: .2 !important;
}

@import "~@aws-amplify/ui/src/Theme.css";
.amplify-block {
    @import "~@aws-amplify/ui/src/Angular";

    .amplify-container {
        padding-bottom: 2em;
    }
}
