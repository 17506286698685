/* You can add global styles to this file, and also import other style files */
@import "~nouislider/distribute/nouislider.min.css";
@import "~@aws-amplify/ui/src/Theme.css";
body {
  padding-top: 51px;
  /*
   * Disable browser pull-to-refresh, as that breaks offline support.
   * (As of writing, only supported by Chrome 62+)
   */
  overscroll-behavior: none;
}

/*
 * Add padding to top or bottom. Works well with col-* style classes.
 */
div.pad-top {
  padding-top: 8px;
}

div.pad-bottom {
  padding-bottom: 8px;
}

/*
 * Buttons
 */
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 0px grey;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
  box-shadow: 2px 2px 6px 0px grey;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
  box-shadow: 3px 3px 8px 0px grey;
}

.btn-float-bottom-right {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 10;
}

/*
 * Text
 */
.text-large {
  font-size: large;
}

.text-x-large {
  font-size: x-large;
}

.text-larger {
  font-size: larger;
}

.text-muted {
  color: #ccc;
}

hr.text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}
hr.text:before {
  content: "";
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}
hr.text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  padding: 0 0.5em;
  line-height: 1.5em;
  color: #818078;
  background-color: #fcfcfa;
}

/*
 * Tables
 */
td.valign-bottom {
  vertical-align: bottom !important;
}

td.valign-middle {
  vertical-align: middle !important;
}

/*
 * Modify formatting in a documentation/aside block.
 */
aside ul, aside ol {
  padding-left: 16px;
}

.help-backdrop {
  opacity: 0.2 !important;
}

.amplify-block {
  /********************************************
  ** Auth
  *******************************************/
  /********************************************
  ** Storage
  *******************************************/
  /**********************************************
  ** Interactions
  **********************************************/
  /************************************
  ** Input Groups
  ************************************/
  /** Ionic styles for material design */
  /***********************************
  ** Forms
  ***********************************/
  /** Ionic styles for material design */
  /************************************
  ** Alert
  ************************************/
  /************************************
  ** Tooltip
  ************************************/
  /* Tooltip container */
  /* Tooltip text */
  /************************************
  ** Ionic
  ************************************/
}
.amplify-block body {
  font-family: var(--font-family);
}
.amplify-block h1 {
  height: 50px;
  font-weight: 500;
}
.amplify-block h2 {
  height: 42px;
  font-weight: 300;
}
.amplify-block h4 {
  height: 28px;
  font-weight: 400;
}
.amplify-block h5 {
  height: 21px;
  font-weight: 400;
}
.amplify-block p {
  height: 16px;
  font-weight: 300;
}
.amplify-block span {
  color: var(--color-gray);
}
.amplify-block input:disabled {
  background-color: #EDEDED;
}
.amplify-block .amplify-component {
  display: block;
}
.amplify-block .amplify-container {
  height: auto;
  padding-bottom: 1em;
}
.amplify-block .amplify-authenticator {
  width: var(--component-width-desktop);
  margin: 0 auto;
  border-radius: 6px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
}
.amplify-block .amplify-signup-container {
  height: 570px;
}
.amplify-block .amplify-reset-password-container {
  height: 270px;
}
.amplify-block .amplify-confirm-reset-password-container {
  height: 360px;
}
.amplify-block .amplify-confirm-signup-container {
  height: 370px;
}
.amplify-block .amplify-confirm-signin-container {
  height: 260px;
}
.amplify-block .amplify-form-actions-left {
  margin-top: 1em;
  color: var(--color-gray);
}
@media (min-width: 320px) and (max-width: 480px) {
  .amplify-block .amplify-authenticator {
    width: var(--component-width-mobile);
    box-shadow: none;
  }
}
.amplify-block .amplify-photo-picker {
  width: var(--component-width-desktop);
  margin: 1em auto;
  border-radius: 6px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
}
.amplify-block .amplify-photo-picker-container {
  width: 400px;
  margin: 0 auto;
  padding: 1em;
}
.amplify-block .amplify-album {
  width: var(--component-width-desktop);
  margin: 1em auto;
  border-radius: 6px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
}
.amplify-block .amplify-album-container {
  width: 400px;
  margin: 0 auto;
  padding: 1em;
}
.amplify-block .amplify-photo-picker-upload {
  height: 212px;
  width: 392px;
  border: 2px dotted #979797;
  border-radius: 4px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABBCAYAAACUyynLAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAASASURBVHic7ZtZe7I6FEbfEMIMbe3p//+BfRyKzBk4F2pFvyJDMdiWda2bZD1JSHY2hHNeY2EQxtwN+Iks0kawSBvBIm0Ei7QRmLofyIXEerOHVOpbcahh4HUVgpl0opb1R/tI28Xpt4UBgFQKuzidoEXD0SqtLDmqSkwWr6oEypJPFq8vWqXFSf4jYnahTVpZclR8ulF2ouL6R5s2afccEbpHGxly9uRcoKg4pFSoB5xY67pGXlRj2tcb17FACOn9e0IASg04FgNjwzYRvaRVlcBun4HfYXo9AoyZeA49WFY/eZ3S0qyc7dWum+fIh+/Znb+7uablRfVnhAGHPWSfZaRVmlL1nxJ2YhenUOr2itUqLc2Lzj//RpSqkebFzd+0Sitm2Gk/Cl19b5UmhJy8MT+Frr7fXNP+KqPXtIV2tOfTvsJiJlzHgmWZYCb93NnXdQ0uJKpKIC+qu5xdxzCrNMsyEQUe7JadOCEEFjNhMROB76CsBOIkmzS9NIbZpD1HHnzPGfQf2zLxtoqQZgV2cXanlnWjXRohBK8v4cXoklIhy0sUJYeQEkrVMAwCk1I4NoPn2qD0vPz6ngPTNLHe7lEPyRxMhHZpq+fgU1hd14iTHEn672ZSqRqVEqi4QJzkCHwHUeB+rne2ZWL1HGC93WttP6D57RmFHhybATjk+N838ZfCviJJC7xv4ov7BcdmiELvLm29hTZplBoIjhmEuq6x3u7B+bANNOfynykZeJdTVwfanhaF3ufUipN8sLATnMuLTC0hRPto0yLNMAjc07SUqveUbCNJC0h5nqauzWAY/bO230WLNMc+p6KzvJwkZjMOIQSObU0Stw9apFnsfAs+VfbkOk7zGfdGizRKzx0ScprsyXWc5jPujRZppLHeTJU9uY5Dftuahkb/plqw/4mj8WCgRVrzTWdONI2u4zSfcW+0SOPinJU4nQi+y3Wc5jPujRZpzVoLz+2+V+zDdZyy0nenoWmkSfBj3p1SA4E/LCV0TeA7F0cnLuToE8YYZimAiQIXbOS+ijGKKHAvY+/15ta0SSuKCtVxCp1yakPFMUbx+hJeFLqUFdd+3ag1PbD5SKGOqR1qGHhbRb2nauA7eFtFoMa5yVIpbD/0VwFoTUJKqbDeJvhvdRgthBA8hR4CzxmUuQUO6aXNNtG61TihPXNbcYH3dYzVSwjzKIJSA2HgIrxaq9oQUmGz3X++XHQzy70nFxLv6w+kWTm4ODDNS7yvP2YTBsx4G3WqSkqyAoHnwHWs1iOWUodKyiQrHqJcYvbLYiEkdnGKXZzCNCmYSWEc347qeFn8CKKazC6tiXhAQV/RuqYNqPn9dXT1vVWazqTeo9HV91ZpjjVNNuIn0tX3VmlTZSN+Il19b5XGGB1coPIb8D2n80x8c3P7FLqtZVC/Edsy8RR2n0o6P76o6xof+xxp9r0L3kfH9xw8hW6vT4V6fxvFhUSWHQ7VUslBx59HhBCAGseEgGcP+kJ50AdlCweWQuURLNJGsEgbwSJtBIu0ESzSRrBIG8H/BsMLCwR4M6kAAAAASUVORK5CYII=") center no-repeat #FBFBFB;
}
.amplify-block .amplify-upload-input {
  margin-top: 0.5em;
}
.amplify-block .amplify-upload-button {
  margin: 0.5em auto !important;
  width: 100% !important;
}
.amplify-block .amplify-photo-picker-preview {
  width: 100%;
}
.amplify-block .amplify-image-container {
  display: inline;
}
.amplify-block .amplify-image {
  width: 30%;
  margin: 0.2em;
  border-radius: 6px;
  border: 2px solid var(--color-white);
  cursor: pointer;
}
@media (min-width: 320px) and (max-width: 480px) {
  .amplify-block .amplify-photo-picker {
    width: var(--component-width-mobile);
  }
  .amplify-block .amplify-image {
    width: var(--component-width-mobile);
  }
  .amplify-block .amplify-album {
    width: var(--component-width-mobile);
  }
  .amplify-block .amplify-album-container {
    width: var(--component-width-mobile);
  }
  .amplify-block .amplify-photo-picker-container {
    width: auto;
  }
  .amplify-block .amplify-photo-picker-upload {
    width: auto;
  }
}
.amplify-block .amplify-interactions {
  width: var(--component-width-desktop);
  margin: 1em auto;
  border-radius: 6px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
}
.amplify-block .amplify-interactions-container {
  width: 400px;
  margin: 0 auto;
  padding: 1em;
}
.amplify-block .amplify-interactions-button {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAABHNCSVQICAgIfAhkiAAAABl0RVh0U29mdHdhcmUAZ25vbWUtc2NyZWVuc2hvdO8Dvz4AAAG2SURBVFiF7dfPKwRhHMfx9+xqhaItIg5KikgOlER2TzhQ4i+QwtndQe4O4uhCSQ57sC6SkotfR1uK2nWgrGJr8yvsjsO2bWtb+8w+88zOYT6nmWfmeebV95lfj6bruo6N4yo1oFAcoGwcoGwcoGwcoGxsDyyz5CqxawhtwPsTNA1C5wxobqGumvJv8d0+HM5B4jvT1jICw1tC3dVO8ds9VNaD25PdHj6A6LnQEOqAkSBs90HsBsZ2wVOVfTweFhpGDTAShMPZ1LQeL+QiNQ1qu0sETOOSidS+ruciu6bB2yE0nLkPyV9c1pU08K9AQx9Ut4AmVhvzKvgfDlKVfDyFmlZhHJgFLIQDaJ+CoVXDQ8sDRXG+dUOVS0cOqBgHMkARXJscDooFiuL8cjgoBmghDoy+B+MR2BmA5I8lODBSQT0JiU/oX8x/jsk4MAJ8CcHeBDQNwcCSJTgwAoxewkcMgpO5SEU4MHIPHs3DbSC1XeGF8QA8nMDzFfjWlODAyC9/9Cyz/fUKF8swvAkuT/4+JkQc2DwKVY3Q0At1PeAuV8jKRP2aRDK2X3Y6QNk4QNk4QNnYHvgLzPueuQw6nCEAAAAASUVORK5CYII=") center no-repeat var(--color-white);
  border: none;
  cursor: pointer;
  width: 32px;
}
.amplify-block .amplify-interactions-actions {
  display: flex;
  border-top: var(--input-border);
  margin-bottom: -1em;
  margin-left: -1.9em;
  margin-right: -1.9em;
}
.amplify-block .amplify-interactions-actions > input[type=text] {
  border: none;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
}
.amplify-block .amplify-interactions-actions > input[type=text]:focus {
  border: 0px solid var(--color-white) !important;
}
.amplify-block .amplify-interactions-conversation {
  margin: 1em;
}
.amplify-block .amplify-interactions-input {
  padding: 1em;
  margin: 1em;
  width: 75%;
  margin-left: 5em;
  border-radius: 20px 20px 0 20px;
  background-color: #009ECF;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: var(--color-white);
  font-size: 13px;
  line-height: 16px;
}
.amplify-block .amplify-interactions-input-timestamp {
  color: #828282;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 16px;
  text-align: right;
}
.amplify-block .amplify-interactions-response-timestamp {
  color: #828282;
  font-size: 10px;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin-left: 1.5em;
}
.amplify-block .amplify-interactions-response {
  padding: 1em;
  margin: 1em;
  width: 75%;
  border-radius: 20px 20px 20px 0;
  background-color: #DBDBDB;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.1);
  font-size: 13px;
  line-height: 16px;
  color: #4A4A4A;
}
@media (min-width: 320px) and (max-width: 480px) {
  .amplify-block .amplify-interactions {
    width: var(--component-width-mobile);
  }
  .amplify-block .amplify-interactions-container {
    width: 85%;
  }
}
.amplify-block .amplify-input-group {
  display: flex;
  margin-left: 0.3em;
  margin-top: 0.5em;
}
.amplify-block .amplify-input-group > div:first-of-type {
  float: left;
}
.amplify-block .amplify-input-group > div:last-child > input {
  border-left: none !important;
  width: 100%;
}
.amplify-block .amplify-input-group > div:last-child {
  display: flex;
  width: 100%;
}
.amplify-block .amplify-input-group > div {
  float: right;
}
.amplify-block .amplify-input-group-item > input {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
.amplify-block .amplify-input-group-label {
  display: block;
}
.amplify-block .amplify-select-phone-country {
  height: 55px;
  width: 68px;
  border: var(--input-border);
  border-radius: 3px 0 0 3px;
  background-color: transparent;
}
.amplify-block .md .amplify-select-phone-country {
  border: none;
  border-bottom: var(--input-border);
  background-color: transparent;
  margin-top: 0em;
  min-width: 100% !important;
  height: 47px !important;
  padding-left: 0;
}
.amplify-block .amplify-form-input-phone-ionic {
  height: 36px;
  min-width: 100%;
  color: var(--color-accent-brown);
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
  border: none;
  border-bottom: var(--input-border);
  border-radius: 0px;
}
.amplify-block .amplify-ionic-grid-padding-left {
  padding-left: 0;
}
@media (min-width: 320px) and (max-width: 480px) {
  .amplify-block {
    /** Ionic styles for material design */
  }
  .amplify-block .amplify-select-phone-country {
    margin-top: 0.1em;
    margin-right: 0em;
    height: 41px;
    width: auto;
  }
  .amplify-block .md .amplify-select-phone-country {
    margin-top: 0.3em;
  }
}
.amplify-block .amplify-form-container {
  height: 100%;
}
.amplify-block .amplify-form-header {
  color: var(--color-primary-accent);
  height: 28px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.49px;
  line-height: 16px;
  margin: 0.2em;
}
.amplify-block .amplify-form-text {
  height: 36px;
  width: 90%;
  color: var(--color-accent-brown);
  font-size: 14px;
  letter-spacing: 0.4px;
  line-height: 20px;
}
.amplify-block .amplify-input-label {
  height: 18px;
  width: 367.92px;
  color: var(--color-accent);
  font-family: var(--font-family);
  font-size: 14px;
  margin: 0.5em;
  letter-spacing: 0.4px;
  line-height: 18px;
}
.amplify-block .amplify-form-input {
  height: 42px;
  width: 378px;
  font-size: 14px;
  letter-spacing: 0.4px;
  border: var(--input-border);
  border-radius: 3px;
  background-color: #FFFFFF;
  padding: var(--input-padding);
  margin: 0.5em;
}
.amplify-block .amplify-form-extra-details {
  margin: 0.5em;
  font-size: 12px;
  color: var(--color-gray);
}
.amplify-block .md .amplify-form-input {
  border: none;
  border-bottom: var(--input-border);
  border-radius: 0px;
}
.amplify-block .amplify-form-actions {
  margin-top: 1.8em;
  padding-bottom: 1.5em;
}
.amplify-block .amplify-signin-username {
  margin-top: 0.8em;
}
.amplify-block .amplify-form-row {
  margin-top: 1em;
}
.amplify-block .amplify-form-action {
  margin: 0.5em;
  font-size: 12px;
}
.amplify-block .amplify-form-signup {
  margin-top: 1em;
  color: var(--color-gray);
}
.amplify-block .amplify-form-body {
  padding: 1.5em;
}
.amplify-block .amplify-form-row {
  display: block;
}
.amplify-block .amplify-form-cell-left {
  float: left;
  margin: 8px 0 8px 8px;
}
.amplify-block .amplify-form-cell-right {
  float: right;
  margin: 8px 8px 8px 0;
}
.amplify-block .amplify-form-link {
  cursor: pointer;
  color: var(--color-primary);
}
.amplify-block .amplify-form-link:hover {
  color: var(--color-primary-highlight);
}
.amplify-block .amplify-form-button {
  margin: 0.4em 0 0 0.5em;
  padding: 0.5em;
  border: none;
  height: var(--button-height);
  width: 155px;
  color: white;
  text-transform: uppercase;
  background-color: var(--color-primary);
  cursor: pointer;
}
.amplify-block .amplify-form-button:hover {
  background-color: var(--color-primary-highlight);
}
.amplify-block .amplify-greeting {
  display: flex;
  padding: 1em;
  margin: 0 auto;
}
.amplify-block .amplify-greeting-flex-spacer {
  width: 30%;
}
.amplify-block .amplify-greeting-sign-out {
  flex: auto;
}
.amplify-block .amplify-footer {
  clear: both;
}
.amplify-block .amplify-input-invalid {
  border-color: red !important;
}
@media (min-width: 320px) and (max-width: 480px) {
  .amplify-block .amplify-form-input {
    width: 100%;
  }
}
.amplify-block .amplify-alert-close {
  float: right;
  cursor: pointer;
  color: var(--color-gray);
  font-size: 24px;
  line-height: 24px;
}
.amplify-block .amplify-alert-close:hover {
  color: var(--color-primary-accent);
}
.amplify-block .amplify-alert-icon {
  float: left;
  font-size: 24px;
  line-height: 24px;
  color: var(--color-danger);
}
.amplify-block .amplify-alert {
  position: absolute;
  min-height: 52px;
  width: 460px;
  margin-top: 5em;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}
.amplify-block .amplify-alert-body {
  padding: 1em;
}
.amplify-block .amplify-alert-message {
  max-width: 85%;
  display: inline-flex;
  color: var(--color-primary-accent);
  font-size: 15px;
  line-height: 24px;
  margin-left: 0.5em;
}
@media (min-width: 320px) and (max-width: 480px) {
  .amplify-block .amplify-alert {
    width: 92%;
    margin-top: 1em;
    margin-left: 4%;
    margin-right: 4%;
  }
}
.amplify-block .tooltip {
  position: relative;
  display: inline-block;
}
.amplify-block .tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: var(--color-primary-accent);
  color: white;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}
.amplify-block .tooltip:hover .tooltip-text {
  visibility: visible;
}
.amplify-block .amplify-phone-ion-content {
  height: 100px;
}
.amplify-block .amplify-container {
  padding-bottom: 2em;
}